<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')" 
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('clear_all') :  getCommonText('clear')}`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td></td>
                    <td class="text-left" colspan="8">{{ getFormText('transport_types')}}</td>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(coefficient)="data">
                    <div class="text-right">
                        {{ data.item.coefficient }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.amount"
                                  @change="v => data.item.amount = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="onBlur(data.item, 'amount', data.item.amount, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="budget-form-val-msg" v-if="variantAttribute && data.item.showValidationError && !data.item.amoun > 0">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(expenses_amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="data.item.amount <= 0 || !data.item.amount"
                                  :value="data.item.expenses_amount"
                                  @change="v => data.item.expenses_amount = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\-?\\d{0,9}$')"
                                  @blur="onBlur(data.item, 'expenses_amount', data.item.expenses_amount, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(contribution_amount)="data">
                    <div class="text-right">
                        {{ $n(data.item.contribution_amount) }}
                    </div>
                </template>
                <template #cell(note)="data">
                    <div v-if="variantAttribute" :class="{ 'error': ((data.item.note && data.item.note.length > 255)
                    || (data.item.expenses_amount !== 0 && data.item.note.trim().length === 0)) }">
                        <div class="note-box">
                            <b-form-input
                                class="text-right"
                                :value="data.item.note"
                                @change="v => data.item.note = v"
                                @keyup.enter.exact="keyup13">
                            </b-form-input>
                            <i v-if="variantAttribute"
                                class="icon icon-more thin-blue pointer icon-18 icon-rotate-90" 
                                @click="onEditNote(data.item)">
                            </i>
                        </div>
                        <template v-if="(data.item.note && data.item.note.length > 255)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("len_limit_255") }}</p>
                            </div>
                        </template>
                        <template v-else-if="(data.item.expenses_amount !== 0 && data.item.note.trim().length === 0)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("required") }}</p>
                            </div>
                        </template>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i :title="getCommonText('clear_rec')" class="icon icon-clear table-remove" v-if="variantAttribute" @click="deleteItem(`${getCommonText('clear_rec')}`, data.item, data.index)"></i>
                    </div>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="3">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(sum) }}</td>
                    <td></td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <modal-note
            ref="setNoteModal"
            :noteFieldName="noteFieldName"
            :getCommonText="getCommonText"
            :onEditNote="onEditNote"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import ModalNote from './components/modal-note.vue';
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form02_123',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        ModalNote,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '02-123',
                name_kk: '',
                name_ru: 'Расчет размера страховой премии при обязательном страховании гражданско-правовой ответственности перевозчика перед пассажирами'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: true,
            openDisabled: false,
            mrp: 0,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            noteFieldName: 'note'
        };
    },
    async mounted() {
        await this.loadDict();
    },
    methods: {
        async loadSpecificData() {
            await this.loadDictNormativeInds();
        },

        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            row.amount = 0;
                            row.expenses_amount = 0;
                            row.note = '';
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    item.amount = 0;
                                    item.expenses_amount = 0;
                                    item.note = '';
                                };
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // очистить значения строки

        downloadRep(fileType) {
            this.isReportUploading = true;
            const newHeader = {
                ...this.header, 
                lang: this.$i18n.locale,
                code_modules: this.moduleCode,
                output_file_type: fileType
            }
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + this.mrp + '/' + JSON.stringify(newHeader),
                    method: 'POST',
                    responseType: 'blob'
                },
                async (data) => {
                    if (data.type === 'application/json') {
                        const text = await data.text();
                        const result = JSON.parse(text);
                        if ('error' in result && 'message' in result) {
                            this.makeToast('danger', 'Ошибка получения файла', `${result.error} - ${result.message}`);
                        }
                    } else {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${this.getCommonText('form')} 02-123.${fileType}`);// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadRep()`, error.toString());
                }
            );
        },

        getFiles(data) {
            this.files = data;
        },

        keyPress(event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }

        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            that.load = true;
            that.budgetForm.splice(0);
            for (const item of that.dict) {
                if (item.code !== '1.0') {
                    const dataItem = {
                        id: this.newRowStartId,
                        code: item.code,
                        coefficient: item.coefficient,
                        amount: 0,
                        expenses_amount: 0,
                        itemToDelete: false,
                        note: ''
                    };
                    Object.defineProperty(dataItem, 'name', {
                        get: () => {
                            const lg = this.$i18n.locale === 'kk' ? 'kk' : 'ru';
                            return item[`name_${lg}`];
                        }
                    });
                    this.newRowStartId--;

                    this.$set(dataItem, 'files', 0);

                    try {
                        this.load = true;
                        const response = await fetch(`/api-py/get-new-row-files-form/${dataItem.id}/${JSON.stringify(this.header)}`);
                        const emptyRowFiles = await response.json();
                        this.$set(dataItem, 'row_files', emptyRowFiles);
                    } catch (error) {
                        this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
                    }

                    Object.defineProperty(dataItem, 'contribution_amount', {
                        get: function () {
                            const sum = ((parseInt(dataItem.amount) * parseFloat((dataItem.coefficient === null ? 1 : dataItem.coefficient)) * parseFloat(that.mrp)) / 1000) + parseFloat(dataItem.expenses_amount);
                            return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                        }
                    });
                    Object.defineProperty(dataItem, 'isRequiredFieldFilled', {
                        get: () => {
                            return dataItem.amount > 0;
                        }
                    });
                    Object.defineProperty(dataItem, 'isExpensesFieldFilled', {
                        get: () => {
                            return dataItem.expenses_amount !== 0 && dataItem.expenses_amount;
                        }
                    });

                    that.budgetForm.push(dataItem);
                }
            }

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                values = await response.json();
                if (response.status === 200) {
                    if (values.length === 0) {
                        that.load = false;
                        return;
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.transport_type) { return item; }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].amount = val.amount;
                    val.expenses_amount ? itemExist[0].expenses_amount = val.expenses_amount : itemExist[0].expenses_amount = 0;
                    itemExist[0].note = val.note;
                    itemExist[0].files = val.files;
                    itemExist[0].row_files = val.row_files;
                }
            });
            that.load = false;
        },

        onBlur(item, fieldName, value, rank) {
            this.inputFixedVldtn(item, fieldName, value, rank);
            this.$nextTick(() => {
                this.resetExpensesAmount(item, fieldName);
                if (item.showValidationError) {
                    if (item.amount > 0) {
                        delete item.showValidationError;
                    }
                }
            });    
        },

        resetExpensesAmount(item, fieldName) {
            if (['amount'].includes(fieldName) && item[fieldName] === 0) {
                const expenseFieldName = 'expenses_amount';
                if (expenseFieldName in item) {
                    this.$set(item, expenseFieldName, 0);
                }
            }
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/passenger_transport/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict()`, error.toString());
            }
        },

        async loadDictNormativeInds() {
            this.mrp = 0;
            try {
                const response = await fetch(`/api-py/dictionary-normative-inds/${this.header.year}/${this.header.cur_year}/${this.header.variant_date_time}`);
                const items = await response.json();
                for (const row of items) {
                    if (row.code === 'MRP') {
                        this.mrp = parseFloat(row.value);
                        return;
                    }
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictNormativeInds`, error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            let error = false;
            const template = (row) => {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'transport_type', row.code);
                this.$set(item, 'amount', parseInt(row.amount));
                this.$set(item, 'expenses_amount', parseFloat(row.expenses_amount));
                this.$set(item, 'note', row.note);
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'contribution_amount', row.contribution_amount);
                values.push(item);
            }
            for (const row of this.budgetForm) {
                const isNewRecord = row.id < 0;
                const isExpensesFieldFilled = row.isExpensesFieldFilled;
                const isRequiredFieldFilled = row.isRequiredFieldFilled;
                const isNoteFilled = row.note.trim().length > 0 && row.note.length < 256;

                if (isNewRecord && !isRequiredFieldFilled) {
                    continue;
                }

                if ((isExpensesFieldFilled && !isNoteFilled)
                    || (!isRequiredFieldFilled && isExpensesFieldFilled)
                    ) {
                    error = true;
                    if (!isRequiredFieldFilled && isExpensesFieldFilled) {
                        this.$set(row, 'showValidationError', true);
                    }
                    continue;
                }

                template(row);
            }

            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'contribution_amount');
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.selectAll = false;
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    this.selectAll = false;
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        getFormText(field, extra = null) {
            const link = 'modules.budget_request.calculation_forms.table_headers.form_n_123.';
            return this.getText(link, field, extra)
        },
    },
    computed: {
        sum() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseInt(row.amount);
            }
            return sum;
        },
        total() {
            return this.totalCalculation(this.budgetForm, 'contribution_amount');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name',
                        label: this.getFormText('transport_type')
                    },
                    {
                        key: 'coefficient',
                        label: this.getFormText('coef_by_year')
                    },
                    {
                        key: 'amount',
                        label: this.getFormText('transport_count')
                    },
                    {
                        key: 'expenses_amount',
                        label: this.getFormText('correction_sum_thnds')
                    },
                    {
                        key: 'contribution_amount',
                        label: this.getFormText('contribution_sum')
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ]
            };
            return [];
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .note-box {
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .icon-more {
        align-self: center;
        position: static;
    }    
    .budget-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
</style>